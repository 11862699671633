<template>
    <div class="col-12 text-center">
        <span><font-awesome-icon icon="times" class="icon red" /></span>
        <h1>{{$t('oops')}}</h1>
        <p v-html="$t('error')"></p>
        <p class="small mt-5" v-if="paymentReference">
            <strong>{{$t('payment-reference')}}:</strong> {{paymentReference}}
        </p>
        <p class="alert alert-danger small mt-2" v-if="amount && currencySymbol">
            <strong>{{$t('amount')}}:</strong> {{(amount)}}<em v-html="currencySymbol"></em>
        </p>
        <a v-if="brandUrl" :href="brandUrl" class="btn btn-light mt-1">{{$t('redirect')}}</a>
    </div>
</template>

<script>
    export default {
        name: 'Error',
        props: {
            type: String,
            amount: Number,
            currencySymbol: String,
            paymentReference: String,
            brandUrl: String,
        },
    }
</script>

<style scoped></style>
